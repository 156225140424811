<template>
  <div v-loading="loading">
    <!--    上部开始-->
    <div class="statistical-top">
      <el-tabs v-model="activeName" class="el-nav">
        <el-tab-pane label="课程分析" name="first">
          <div class="statistical-center">
            <div class="btn-list vertical-center">
              <div class="left">数据总览</div>
              <div class="btn-list-right">
                共 <span>{{ courseTotal }}</span> 个课程
              </div>
            </div>
            <div class="statistical-center-list space-between">
              <div class="statistical-center-item">
                <div class="statistical-center-item-title">学员总数</div>
                <div class="statistical-center-item-num">
                  {{ Number(total.learn_content) + Number(total.buy_count) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="Number(yesterday_data.people_count) === 0 ? 'noBg' : Number(yesterday_data.people_count) > 0 ? 'blueBg' : 'redBg'"
                    >{{ Number(yesterday_data.people_count) === 0 ? '' : Number(yesterday_data.people_count) > 0 ? '+' : '-' }}{{ yesterday_data.people_count }}</el-tag
                  >
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>本周日均新增人数：{{ week_data.people_count }}</span>
                </div>
              </div>
              <div class="statistical-center-item">
                <div class="statistical-center-item-title">收藏总数</div>
                <div class="statistical-center-item-num">
                  {{ Number(total.collect_count) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="Number(yesterday_data.collect_count) === 0 ? 'noBg' : Number(yesterday_data.collect_count) > 0 ? 'blueBg' : 'redBg'"
                    >{{ Number(yesterday_data.collect_count) === 0 ? '' : Number(yesterday_data.collect_count) > 0 ? '+' : '-' }}{{ yesterday_data.collect_count }}</el-tag
                  >
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>本周日均收藏量：{{ week_data.collect_count }}</span>
                </div>
              </div>
              <div class="statistical-center-item">
                <div class="statistical-center-item-title">评论总数</div>
                <div class="statistical-center-item-num">
                  {{ Number(total.comment_count) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="Number(yesterday_data.comment_count) === 0 ? 'noBg' : Number(yesterday_data.comment_count) > 0 ? 'blueBg' : 'redBg'"
                    >{{ Number(yesterday_data.comment_count) > 0 ? '+' : '' }}{{ Number(yesterday_data.comment_count) }}</el-tag
                  >
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>本周日均评论量：{{ week_data.comment_count }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="el-nav-right">
        每天
        <span>1</span>
        点左右更新前一日数据
      </div>
    </div>

    <!--上部结束-->

    <div>
      <!--全部-->
      <course @getTotal="getTotal" ref="course" v-if="activeIndex === 0"></course>
    </div>
  </div>
</template>

<script>
import course from './components/course';
export default {
  components: {
    course: course,
  },
  name: 'Index',
  data() {
    return {
      loading: false,
      activeName: 'first',
      activeIndex: 0,
      readingsNum: 698457,
      likesNum: 15,
      commentsNum: 0,
      cocollectionsNum: 0,
      readingsOldNum: 0,
      likesOldNum: 15,
      commentsOldNum: -2,
      cocollectionsOldNum: 0,
      activeIndex: 0,
      yesterday_data: {},
      week_data: {},
      total: {},
      courseTotal: 0,
      tableData: [],
    };
  },
  created() {
    this.activeIndex = 0;
    switch (this.$route.query.type) {
      case 'article':
        this.activeIndex = 1;
        break;
      case 'video':
        this.activeIndex = 2;
        break;
      case 'replay':
        this.activeIndex = 3;
        break;
      case 'posts':
        this.activeIndex = 4;
        break;
      default:
        this.activeIndex = 0;
    }
    this.getOverView();
  },

  mounted() {
    this.numThousand(12);
    // 清空路由参数
    this.$router.push({ query: {} });
    this.getTotal();
  },
  methods: {
    getTotal(val) {
      if (val) {
        this.courseTotal = val;
      } else {
        this.courseTotal = 0;
      }
    },
    // 获取数据总览
    getOverView() {
      this.loading = true;
      let url = `${this.$api.Overview}?content_type=3`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.loading = false;
          this.yesterday_data = res.data.data.yesterday_data;
          this.week_data = res.data.data.week_data;
          this.total = res.data.data.total;
        }
      });
    },
    isNullorEmpty(str) {
      return str === '' || str === null || str === undefined || isNaN(str);
    },
    // 数字三位数一分
    numThousand(num, str) {
      if (this.isNullorEmpty(num)) return '--';
      str = str || '';
      const isNegative = !(num >= 0);
      //是否负数
      if (isNegative) num = Math.abs(num);
      // 获取整数和小数部分
      let intNum = Math.trunc(num),
        flotNum = '';
      if (num.toString().split('.').length > 1) {
        flotNum = num.toString().split('.')[1];
      }
      // 整数部分 千位加逗号
      let c = intNum && intNum !== 0 ? intNum.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,') : '0';
      //是否负数
      if (isNegative) c = '-' + c + '.' + flotNum;
      // return str + c;
      console.log(str + c);
    },
    choseBtn(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/data/content/index.less';
/deep/.el-tabs__header {
  margin: 0;
}
.btn-list {
  .left {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #71777d;
  }
}
.el-nav {
  .statistical-center {
    .statistical-center {
      margin-top: 30px;
    }

    .statistical-center-item {
      width: 294px;
    }
  }
}
</style>
